import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/cloud/thingos-homepage/packages/meshlighting/src/components/BlogLayout.tsx";
import { graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { BlogImageWrapper } from '../../components/BlogImageWrapper';
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		matterTransportLayer: file(relativePath: { eq: "blog-posts/matter-transport-layer.png" }) {
			childImageSharp {
				gatsbyImageData(width: 1200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
			}
		}
		matterDevices: file(relativePath: { eq: "blog-posts/matter-devices.png" }) {
			childImageSharp {
				gatsbyImageData(width: 1200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
			}
		}
	}
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`so der Präsident & CEO der CSA (Connectivity Standard Alliance), Tobin Richardson. Es gäbe zwar noch einen heftigen Wettbewerb in der Smart-Home Branche – die technischen, sowie geschäftlichen Führungskräfte der Smart-Home Unternehmen erkennen jedoch, dass der Markt nur durch Zusammenarbeit vorangebracht werden kann.`}</p>
    <h4>{`Die Qual der Wahl`}</h4>
    <p>{`Wer heute Smart-Home Geräte kauft, stößt bei der Auswahl auf eine Vielzahl unterschiedlicher Technologien und Plattformen, die verschiedene Vorzüge und Limitierungen mit sich bringen. So bietet beispielsweise das eine Ökosystem eine große Auswahl an Produkten, setzt gleichzeitig jedoch ein Gateway vorraus. Ein Anderes punktet mit seiner hohen Datenübertragungsrate, ist wegen des hohen Energiebedarfs jedoch für batteriebetriebene Geräte ungeeignet. Ein weiteres System erlaubt es Geräte direkt über das Smartphone zu steuern, jedoch nicht über Sprachassistenten. Eines haben jedoch alle Smart-Home Systeme miteinander gemein: Mit den Geräten der Mitbewerber können sie in den allermeisten Fällen nicht kommunizieren.`}</p>
    <p>{`Momentan muss sich ein Nutzer daher entweder auf ein Ökosystem begrenzen, wodurch er sich zwar einer bestmöglichen Kompatibilität seiner Produkte sicher sein kann, gleichzeitig jedoch die vielfältige Auswahl der am Markt verfügbaren Produkten verliert. Oder er etabliert mehrere Systeme bei sich zu Hause, mit dem Nachteil, dass die Konnektivität zwischen den Smart-Home Produkten verloren geht. Wirklich “smart“ ist das nicht. Denn erst wenn alle Geräte im Haushalt miteinander kommunizieren können, kann sich das volle Potenzial richtig entfalten und Anwendungen, wie komplexe Hausautomationen können entstehen.`}</p>
    <h4>{`Die Branche hat das Problem erkannt und entwickelt einen gemeinsamen Standard`}</h4>
    <div style={{
      "paddingTop": "20px",
      "paddingBottom": "30px"
    }}>
	<GatsbyImage image={getImage(props.data.matterTransportLayer)} alt="Matter Transport Layer" mdxType="GatsbyImage" />
    </div>
    <p>{`Die Connectivity Standards Alliance (ehemals Zigbee Alliance) möchte durch die Entwicklung des einheitlichen Kommunikationsstandards Matter dieses Interoperabilitätsproblem ein für alle Mal lösen. Um dabei nicht einfach nur einen weiteren konkurrierenden Standard zu schaffen, setzt die Non-Profit Corporation auf Technologieoffenheit und unterstützt in der ersten Version vier Transport Layer: Bluetooth® um Geräte in das Netzwerk einzubinden – Ethernet, WLAN und Thread für die eigentliche Kommunikation zwischen den Geräten. Vereinfacht gesagt, definiert Matter die Sprache über die sich die Geräte austauschen, über welche Übertragungstechnologie dies geschieht entscheidet der Gerätehersteller, je nach Bedarf, selbst.`}</p>
    <h4>{`One Question: “Will Matter matter?“`}</h4>
    <p>{`Es sind allem voran die Ankündigungen der Tech-Giganten Apple, Google und Amazon zukünftig Matter vollumfänglich zu unterstützen, weshalb Branchenexperten Matter eine sehr hohe Erfolgschance zusprechen. Selten herrschte in der Branche solch eine Einigkeit und wird von konkurrierenden Firmen so energisch vorangetrieben. Schon heute unterstützt Apples Homepod und Google Home WLAN und Thread. Auch Amazon kündigte an Thread bis zum geplanten Start von Matter in die Sprachassistenten zu integriert. Alle werden von Anfang an via Matter kommunizieren können. Ein Signal, das wiederum viele andere Unternehmen ermutigt hat erste Matter-Produkte anzukündigen, oder sogar vorzustellen. So haben mehr als ein dutzend Firmen den Matter-Support im Allgemeinen oder zu im Zusammenhang mit einzelnen Produkten auf der diesjährigen CES angekündigt. Darunter Firmen wie LG, Nanoleaf, Belkin, Samsung und Eve. Die CSA geht zum Start von über 50 verfügbaren Geräten aus.`}</p>
    <h4>{`Welche Bereiche in meinem Zuhause werden Matter unterstützen?`}</h4>
    <div style={{
      "paddingTop": "20px",
      "paddingBottom": "30px"
    }}>
	<GatsbyImage image={getImage(props.data.matterDevices)} alt="Supported Devices" mdxType="GatsbyImage" />
    </div>
    <p>{`Zwar muss davon ausgegangen werden, dass der Funktionsumfang Anfangs noch relativ rudimentär sein wird – smarte Steckdose zb. werden nur den Status on/off empfangen können, nicht jedoch den Stromverbrauch übermittel können – dafür wird Matter bereits von Anfang an mit einer breiten Abdeckung an Geräten auftrumpfen. Folgende Bereiche werden voraussichtlich unterstützt:`}</p>
    <p>{`Matter Support ab Release:`}</p>
    <ul>
      <li parentName="ul">{`Licht`}</li>
      <li parentName="ul">{`Leuchten`}
        <ul parentName="li">
          <li parentName="ul">{`LED-Birnen`}</li>
          <li parentName="ul">{`Schalter`}</li>
          <li parentName="ul">{`Steckdosen`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Türschlösser`}</li>
      <li parentName="ul">{`Thermostate`}</li>
      <li parentName="ul">{`Heizung, Lüftung und Klimatechnik`}</li>
      <li parentName="ul">{`Jalousien und Schattierungen`}</li>
      <li parentName="ul">{`Home Security Sensoren`}
        <ul parentName="li">
          <li parentName="ul">{`Tür & Fenster Sensoren`}</li>
          <li parentName="ul">{`Bewegungsmelder`}</li>
          <li parentName="ul">{`Rauch- und Kohlenmonoxid Detektoren`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Elektrische Garagentore`}</li>
      <li parentName="ul">{`Wireless Access Points`}</li>
      <li parentName="ul">{`Bridges/Gateways`}</li>
      <li parentName="ul">{`TV’s`}</li>
    </ul>
    <p>{`Matter Support für die Zukunft geplant:`}</p>
    <ul>
      <li parentName="ul">{`Staubsaugerroboter`}</li>
      <li parentName="ul">{`Energiemanagement`}
        <ul parentName="li">
          <li parentName="ul">{`Solarpanels`}</li>
          <li parentName="ul">{`Ladestationen für Elektrofahrzeuge`}</li>
          <li parentName="ul">{`Heim-Batteriespeicher`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Kameras`}</li>
    </ul>
    <h4>{`Warten oder Kaufen?`}</h4>
    <p>{`Es scheint, als sei das Jahr 2022 das Jahr in dem die Smart-Home Branche sich beginnt zu wandeln. Weg von proprietären Insellösungen, hin zu einer interoperablen Zukunft, in der herstellerübergreifend Geräte miteinander kommunizieren und Daten austauschen. Zwar bleibt abzuwarten wie schnell sich der Matter-Standard in Deutschland durchsetzt. Wer aktuell Smart-Home Geräte zulegen möchte, findet auf den Herstellerseiten jedoch oftmals schon heute Informationen zu den zukünftigen Matter-Updatemöglichkeiten der einzelnen Geräte.`}</p>
    <h4>{`Sind Ihre Produkte bereit für Matter?`}</h4>
    <p>{`Ihre Produkte sind in der Smart-Home Welt Zuhause und Sie möchten sich unverbindlich zu möglichen Update und Upgrade Möglichkeiten informieren? Schreiben Sie uns gerne ein E-Mail oder rufen Sie uns an.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      